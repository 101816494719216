@import '../shared';

.quick-reorder {
  .products-counter {
    @extend .small;
  }

  .no-products {
    @extend .mb-0;
  }

  .quick-reorder {
    @extend .border;

    .quick-reorder-heading {
      @extend .bg-gray-200, .text-uppercase, .small;
    }

    .product-list {
      .product {
        position: relative;
        &.main {
          &:not(:first-child) {
            @extend .border-top, .border-gray-300;
          }

          &:before {
            content: '';
            display: block;
            width: 4px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: $primary;
          }
        }

        .separator-col {
          .separator {
            @include media-breakpoint-up(sm) {
              width: calc(100% - 130px);
            }
            @include media-breakpoint-down(sm) {
              width: 100%;
            }
            @extend .border-top;
          }
        }

        &:not(.main) {
          @include media-breakpoint-down(sm) {
            .image-col {
              display: none;
            }
          }
        }

        .image-col > div {
          width: 130px;

          a {
            @extend .d-block, .bg-gray-100, .p-3;
          }

          img {
            mix-blend-mode: multiply;
          }
        }

        .product-info-col {
          .product-title {
            @extend .small, .fw-bold, .mb-1;
            a {
              @extend .text-decoration-none, .text-black;
            }
          }

          .sku-title {
            @extend .extra-small, .mb-2;
          }

          .price-block {
            @extend .extra-small;

            .discount-percentage {
              @extend .text-white, .d-flex, .align-items-center, .bg-primary, .mx-2, .px-1;
            }

            .special-price {
              @extend .fw-bold;
            }

            .price {
              @extend .fw-bold;
            }
          }
        }

        .quantity-col {
          select {
            width: 60px;
            @extend .small;
          }
        }

        .add-to-cart-col {
          .btn {
            @extend .small;
          }

          &.product-not-available {
            @include media-breakpoint-down(sm) {
              & > div {
                width: 100%;
                display: grid;
              }
            }
          }
        }
      }
    }
  }
}
